import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Main",
    component: () => import("@/views/main/Index.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/home/Index.vue"),
      },
      // Corporate Governance
      {
        path: "/corporate/Board",
        name: "Board",
        component: () => import("@/views/corporate/Board.vue"),
      },
      {
        path: "/corporate/Committee",
        name: "Committee",
        component: () => import("@/views/corporate/Committee.vue"),
      },
      {
        path: "/corporate/Governance",
        name: "Governance",
        component: () => import("@/views/corporate/Governance.vue"),
      },
      // Press Releases
      {
        path: "/pressreleases/press_releases",
        name: "PressReleases",
        component: () => import("@/views/pressreleases/PressReleases.vue"),
      },
      // Investor Kits
      {
        path: "/investorkits/investor_kits",
        name: "InvestorKits",
        component: () => import("@/views/investorkits/InvestorKits.vue"),
      },
      {
        path: "/investorkits/NewInvestor",
        name: "NewInvestor",
        component: () => import("@/views/investorkits/NewInvestor.vue"),
      },
      {
        path: "/investorkits/webcast",
        name: "Webcast",
        component: () => import("@/views/investorkits/Webcast.vue"),
      },
      // Financial Information
      {
        path: "/financial/Interim1",
        name: "Interim1",
        component: () => import("@/views/financial/Interim1.vue"),
      },
      {
        path: "/financial/Interim2",
        name: "Interim2",
        component: () => import("@/views/financial/Interim2.vue"),
      },
      {
        path: "/financial/Interim3",
        name: "Interim3",
        component: () => import("@/views/financial/Interim3.vue"),
      },
      // Shareholder Services
      {
        path: "/shareholder/Investor",
        name: "Investor",
        component: () => import("@/views/shareholder/Investor.vue"),
      },
      {
        path: "/shareholder/Contact",
        name: "Contact",
        component: () => import("@/views/shareholder/Contact.vue"),
      },
      {
        path: "/shareholder/Annual",
        name: "Annual",
        component: () => import("@/views/shareholder/Annual.vue"),
      },


      // // 关于分蛋
      // {
      //   path: "/about/aboutfd_home",
      //   name: "AboutfdHome",
      //   component: () => import("@/views/about/AboutfdHome.vue"),
      // },
      // // 权威认证点击详情1
      // {
      //   path: "/activityinfo/activityinfo1",
      //   name: "Activityinfo1",
      //   component: () => import("@/views/activityinfo/Activityinfo1.vue"),
      // },




      // // 产品
      // {
      //   // 数字婚嫁(婚嫁互娱)
      //   path: "/product/digital_marriage",
      //   name: "DigitalMarriage",
      //   component: () => import("@/views/product/DigitalMarriage.vue"),
      // },
      // {
      //   // 数字财税(小型企业SaaS管理云,初创企业SaaS管理云,小微企业管理上云)
      //   path: "/product/finance_tax",
      //   name: "FinanceTax",
      //   component: () => import("@/views/product/FinanceTax.vue"),
      // },
      // {
      //   // 云产品(云产品)
      //   path: "/product/cloud_product",
      //   name: "CloudProduct",
      //   component: () => import("@/views/product/CloudProduct.vue"),
      // },
      // // 解决方案
      // {
      //   // 消费金融解决方案(消费金融)
      //   path: "/solution/consumer_finance",
      //   name: "ConsumerFinance",
      //   component: () => import("@/views/solution/ConsumerFinance.vue"),
      // },
      // {
      //   // 婚嫁解决方案(婚礼堂，婚嫁互娱)
      //   path: "/solution/marriage",
      //   name: "Marriage",
      //   component: () => import("@/views/solution/Marriage.vue"),
      // },
      // {
      //   // 零售解决方案(餐饮零售)
      //   path: "/solution/retail",
      //   name: "Retail",
      //   component: () => import("@/views/solution/Retail.vue"),
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
